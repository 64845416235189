import React, { useMemo, JSXElementConstructor } from 'react';
import Link from 'next/link';
import styled, {css} from 'styled-components';

import Icon from 'components/icons/icon';

import theme, {bp, color} from 'settings/theme';

import storyblokService from 'services/storyblokService.js'
import multiOptionsToObject from 'components/blocks/utils/multiOptionsToObject';
import getTemplateFromUrl from 'utils/getTemplateFromUrl';


const calculateSmallMinWidth = (displaySettings) => {
  return displaySettings.minimizeOnDesktop && !displaySettings.minimizeOnMobile ? 768 : 0;
}

const calculateSmallMaxWidth = (displaySettings) => {
  return displaySettings.minimizeOnMobile && !displaySettings.minimizeOnDesktop ? 767 : 100000;
}

type Props = {
  mainImageUrl?: any,
  mainImageMode?: string,
  useImagePlaceholder?: boolean,
  alignContent?: string,
  title?: string,
  subtitle?: string,
  text?: string,
  link?: any,
  linkText?: string,
  displaySettings?: string[],
  className?: string
};

type StyledProps = Props & {
  showSmallCard: boolean,
  smallCardMinWidth: number,
  smallCardMaxWidth: number
};

// Component
const Component: React.FC<Props> = (props) => {

  const hasLink = useMemo(() => !!props.link, [props.link]);
  // const hasLink = useMemo(() => (!!props.link?.cached_url || !!props.link?.url), [props.link]);

  const linkTemplate = useMemo(() => {
    return hasLink && props.link.linktype === 'story' ? getTemplateFromUrl(props.link?.cached_url) : undefined;
  }, [props.link?.cached_url, props.link?.linktype, hasLink]);

  // Main Image
  const mainImageUrl = useMemo(() => {

    let url = typeof props.mainImageUrl === 'string' ? props.mainImageUrl : props.mainImageUrl?.filename ?? undefined;

    if(url){
      url =  url?.indexOf('a.storyblok.com') ? storyblokService.createResizedImageUrl(url, 400) : url || '/images/no-image.png';
    }
    else{
      url = '/images/no-image.png';
    }

    return url;

  }, [props.mainImageUrl]);

  const CardImage = useMemo(() => {
    
    const MainImage = () => <div className="card-image"></div>;
    MainImage.displayName = 'MainImage';
  
    const PlaceholderImage = () => (
      <div className="card-image-placeholder">
        <div>
          <Icon iconName="wheelchair" />
        </div>
      </div>
    );
    PlaceholderImage.displayName = 'PlaceholderImage';
  
    const EmptyComponent = () => null;
    EmptyComponent.displayName = 'EmptyComponent';
  
    if(mainImageUrl){
      return MainImage;
    }
    else if(!mainImageUrl && props.useImagePlaceholder){
      return PlaceholderImage;
    }
    return EmptyComponent;
  
  }, [mainImageUrl, props.useImagePlaceholder]);



  // Settings and dimensions
  const displaySettings = useMemo(() => multiOptionsToObject(props.displaySettings), [props.displaySettings]);

  const showSmallCard = useMemo(() => (displaySettings.minimizeOnMobile || displaySettings.minimizeOnDesktop ? true : false), [displaySettings]);

  const smallCardMinWidth = useMemo(() => calculateSmallMinWidth(displaySettings), [displaySettings]);
  const smallCardMaxWidth = useMemo(() => calculateSmallMaxWidth(displaySettings), [displaySettings]);


	return(

    <StyledComponent 
      {...props} 
      mainImageUrl={mainImageUrl}
      showSmallCard={showSmallCard} 
      smallCardMinWidth={smallCardMinWidth} 
      smallCardMaxWidth={smallCardMaxWidth}
      className={`card ${props.className ? props.className : ''}`}
    >

      {/* Image */}
      {hasLink && props.link.linktype === 'story' && 
        <Link href={linkTemplate} as={`/${props.link?.cached_url}`} className="card-image-link">
          <CardImage />
        </Link>
      }

      {hasLink && (props.link.linktype === 'url' || props.link.linktype === 'asset') && 
        <div>
          <a href={`${props.link.url}`}>
            <CardImage />
          </a>
        </div>
      }

      {hasLink && props.link.linktype === 'link' && 
        <Link href={props.link.href} as={props.link.as} className="card-image-link">
          <CardImage />
        </Link>
      }

      {!hasLink && <CardImage />}


      {/* Details */}
      <div className="card-details paddingHorizontal paddingVertical">
          
        {(props.title || props.subtitle) &&
          <div className="card-titles">
          
            {props.title &&
              <h4 className="card-title">{props.title}</h4>
            }

            {props.subtitle &&
              <div className="card-subtitle">{props.subtitle}</div>
            }
          
          </div>
        }


        {props.text &&
          <div className="card-text">{props.text}</div>
        }

        {hasLink && 
          <div className="card-link">
        
            {props.link.linktype === 'story' &&
              <Link href={linkTemplate} as={`/${props.link.cached_url}`}>
                {props.linkText}
              </Link>
            }

            {(props.link.linktype === 'url' || props.link.linktype === 'asset') &&
              <a href={props.link.url}>
                {props.linkText}
              </a>
            }

            {props.link.linktype === 'link' &&
              <Link href={props.link.href} as={props.link.as}>
                {props.linkText}
              </Link>
            }

          </div>
        }
          

      </div>

      {/* Small link */}
      <div className="card-small-link">
        <Icon iconName="chevron" iconDirection="right" />
      </div>


      {/* Link Cover */}
      {hasLink && props.link.linktype === 'story' && 
        <Link href={linkTemplate} as={`/${props.link.cached_url}`}>
          <div className="card-link-cover"></div>
        </Link>
      }

      {hasLink && (props.link.linktype === 'url' || props.link.linktype === 'asset') && 
        <a href={props.link.url}>
          <span className="card-link-cover"></span>
        </a>
      }

      {hasLink && props.link.linktype === 'link' && 
        <Link href={props.link.href} as={props.link.as}>
          <div className="card-link-cover"></div>
        </Link>
      }


    </StyledComponent>
        
	);

}

Component.defaultProps = {
  mainImageMode: 'cover',
  useImagePlaceholder: false,
  alignContent: "left",
  displaySettings: []
} as Partial<unknown>;

export default Component;


// Styles
const StyledComponent = styled.div<StyledProps>`

  position: relative;
  display: flex;
  flex-direction: column;
  border-top: solid 1px ${color.lines};
  border-bottom: solid 1px ${color.lines};
  background-color: white;

  text-align: ${props => props.alignContent ? props.alignContent : 'left'};

  .card-image{
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    background: ${props => props.mainImageUrl ? `url('${props.mainImageUrl || props.mainImageUrl.filename}')` : '#444'};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  ${props => props.mainImageMode === 'containAndPreserveAspectRatio' && css`

    .card-image-link{
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .card-image{
      background-size: contain;
      width: 50%;
      margin: 20px auto 0 auto;
    }

  `}

    .card-image-placeholder{

      position: relative;
      overflow: hidden;
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      background-color: ${color.grey(90)};

      > div{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .icon{

          width: 60px;
          height: 60px;

          svg{
            fill: ${color.grey(20)};
          }

        }

      }

    }

    .card-details{
      flex: 1 1 auto;
    }

    .card-titles{
      margin-bottom: 0.8rem;
    }

    .card-title{
      margin-bottom: 0.2rem;
      line-height: 1.4;
      color: ${color.primary};
    }

    .card-subtitle{
      color: ${color.grey(60)};
      font-size: 14px;
      font-weight: bold;
    }

    .card-text{
      font-size: 14px;
    }

    .card-link{

      font-family: ${theme.typography.fontFamilySecondary};
      color: ${color.primary};
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      transition: all 0.15s ease-out;

      &:not(:first-child){
        margin-top: 1rem;
      }

      &:hover{
        opacity: 0.6;
      }

    }

    .card-small-link{
      display: none;
    }

    .card-link-cover{
      display: none;
    }


    @media (min-width: ${bp(3)}) {
      border-left: solid 1px ${color.lines};
      border-right: solid 1px ${color.lines};
    }


    ${props => props.showSmallCard === true &&
      css`
          
        @media (min-width: ${props.smallCardMinWidth}px) and (max-width: ${props.smallCardMaxWidth}px ) {

          flex-direction: row;
          text-align: left;

          .card-image{
            width: 120px;
            padding-top: 120px;
          }


          ${props.mainImageMode === 'containAndPreserveAspectRatio' && css`
  
            .card-image-link{
              margin: 10px;
            }

            .card-image{
              width: 100px;
              margin: 0;
            }

            .card-details{
              padding-left: 10px;
            }

          `}

          .card-text{
            display: none;
          }

          .card-small-link{

            flex: 0 0 auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;

            &:before{
              content: ' ';
              position: absolute;
              width: 1px;
              left: 0;
              top: 25px;
              bottom: 25px;
              background-color: #E5E8EB;
            }

            .icon{

              width: 15px;
              height: 15px;

              svg{
                fill: #ACB3B9;
              }

            }

          }

          .card-link-cover{
            z-index: 1;
            position: absolute;
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          }

        }

      `
    }


`