import React from 'react'
import styled from 'styled-components'

import theme, {bp, color} from 'settings/theme'


// Component
const Component = (props) => {

	return(
    <StyledComponent className={`pageWrapper ${props.className ? props.className : ''}`}>
      {props.children}
    </StyledComponent>
	)

}

export default Component


// Styles
const StyledComponent = styled.div`
    
  min-height: calc(100vh - 120px);
  margin: auto;
  max-width: 1436px;

  /* Hack, stops margin collapsing and leaving white border at the top when child element has a top margin */
  /* overflow: auto;  */

  /* New Hack for same thing  */
  margin-top: -1px;
  padding-top: 1px;
  margin-bottom: -1px;
  padding-bottom: 1px;
  
  
  background-color: ${color.pageBackground};

  @media (min-width: ${bp(4)}) {
    min-height: calc(100vh - 140px);
  }

  @media(min-width: 1456px){
    border-left: solid 1px ${color.lines};
    border-right: solid 1px ${color.lines};
  }

`