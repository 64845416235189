import React, { useMemo } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

import theme, {bp} from 'settings/theme';

const Review = dynamic(() => import('components/blocks/blockPartials/review'), {});
const Card = dynamic(() => import('components/blocks/blockPartials/card'), {});


type Props = {
  items?: [],
  children?: React.ReactNode,
  className?: String
};

// Component
const Component: React.FC<Props> = (props) => {

  const items = props.items ?? [];

	return(
    <StyledComponent className={`grid ${props.className ? props.className : ''}`}>

      {/* Content as Data */}
      {items.length > 0 && items.map((item: any, index: number) => (
        <div className="grid-item" key={index}>

          {/* Review */}
          {item.component === 'review' && 
            <Review
              text={item.text}
              stars={item.stars} 
              reviewerName={item.reviewer_name}
            />
          }


          {/* Card */}
          {item.component === 'card' && 
            <Card
              mainImageUrl={item.main_image}
              title={item.title}
              subtitle={item.subtitle}
              text={item.text}
              link={item.link}
              linkText={item.link_text}
              displaySettings={item.display_settings}
            />
          }

        </div>
      ))}


      {/* Content as Data */}
      {items.length === 0 && props.children &&
          <>
              {props.children}
          </>
      }

    </StyledComponent>
	)

}


Component.defaultProps = {
    items: []
} as Partial<Props>


export default Component


// Styles
const StyledComponent = styled.div`

    display: grid;
    grid-gap: 30px;
    width: 100%;

    .grid-item{

        display: flex;
        width: 100%;

        > *{
            margin-bottom: 0;
            display: flex;
            width: 100%;
        }

    }

    @media (min-width: ${bp(3)}) {

        grid-template-columns: 1fr 1fr;
        
    }

    @media (min-width: ${bp(4)}) {

        grid-template-columns: 1fr 1fr 1fr;

    }

    @media (min-width: ${bp(6)}) {

        grid-template-columns: 1fr 1fr 1fr 1fr;

    }


`