import React from 'react'
import dynamic from 'next/dynamic'


// Blocks
const BlockAutoplayVideo = dynamic(() => import('components/blocks/blockAutoPlayVideo'), {});
const BlockButtonGroup = dynamic(() => import('components/blocks/blockButtonGroup'), {});
const BlockCard = dynamic(() => import('components/blocks/blockCard'), {});
const BlockFaqs = dynamic(() => import('components/blocks/blockFaqs'), {});
const BlockGoogleMap = dynamic(() => import('components/blocks/blockGoogleMap'), {});
const BlockGrid = dynamic(() => import('components/blocks/blockGrid'), {});
const BlockHeaderWhiteBox = dynamic(() => import('components/blocks/blockHeaderWhiteBox'), {});
const BlockHtml = dynamic(() => import('components/blocks/blockHtml'), {});
const BlockImageGallery = dynamic(() => import('components/blocks/blockImageGallery'), {});
const BlockLargeImage = dynamic(() => import('components/blocks/blockLargeImage'), {});
const BlockReview = dynamic(() => import('components/blocks/blockReview'), {});
const BlockRichText = dynamic(() => import('components/blocks/blockRichText'), {});
const BlockLargeVideo = dynamic(() => import('components/blocks/blockLargeVideo'), {});
const BlockWhiteSection = dynamic(() => import('components/blocks/blockWhiteSection'), {});
const BlockLinkedContent = dynamic(() => import('components/blocks/blockLinkedContent'), {});


type Props = {
    content: Object[]
}

const Component: React.FC<Props> = (props) => {

    return (
        <>
            {props.content && props.content.map((contentItem: any, index: number) => (

                <React.Fragment key={index}>
                    {contentItem.component === 'autoplay-video' && <BlockAutoplayVideo block={contentItem} />}
                    {contentItem.component === 'button-group' && <BlockButtonGroup block={contentItem} />}
                    {contentItem.component === 'card' && <BlockCard block={contentItem} />}
                    {contentItem.component === 'faqs' && <BlockFaqs block={contentItem} />}
                    {contentItem.component === 'google-map' && <BlockGoogleMap block={contentItem} />}
                    {contentItem.component === 'grid' && <BlockGrid block={contentItem} />}
                    {contentItem.component === 'header-with-white-box' && <BlockHeaderWhiteBox block={contentItem} />}
                    {contentItem.component === 'html' && <BlockHtml block={contentItem} />}
                    {contentItem.component === 'large-image' && <BlockLargeImage block={contentItem} />}
                    {contentItem.component === 'image-gallery' && <BlockImageGallery block={contentItem} />}
                    {contentItem.component === 'linked-content-block' && <BlockLinkedContent block={contentItem} />}
                    {contentItem.component === 'review' && <BlockReview block={contentItem} />}
                    {contentItem.component === 'rich-text' && <BlockRichText block={contentItem} />}
                    {contentItem.component === 'large-video' && <BlockLargeVideo block={contentItem} />}
                    {contentItem.component === 'white-section' && <BlockWhiteSection block={contentItem} />}
                </React.Fragment>

            ))}

        </>

    )
}

export default Component
