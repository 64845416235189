// Date
const dateLens = function (input) {

  let displayDate;

  try {
    let dateString = input.split(' ')[0];
    let dateParts = dateString.split('-');
    displayDate = new Date(dateParts[0], parseInt(dateParts[1]) -1, dateParts[2]).toDateString();
  } catch (error) {
    return '';
  }

  return displayDate;
    
};

export const date = dateLens;